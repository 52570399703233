import { toRef } from 'vue';
import type {AsyncData} from '#app';
import type {SeoFallback} from '~/interfaces/seo';
import { useEtherSeo } from '~/composables/useEtherSeo';

type usePageQueryOpts = {
    // applySeo?: boolean;
    entryKey?: string;
    activeSite?: any;
} & SeoFallback;

const defaultOpts = {
    // applySeo: true,
    entryKey: undefined,
    activeSite: undefined,
} as usePageQueryOpts;


// Generic implementation of useLazyAsyncQuery & useCraftPage
const usePageQuery = (
    response: AsyncData<any, any>,
    opts: Partial<usePageQueryOpts> = defaultOpts,
) => {

    const craftEntry = useEtherPage(response, opts.entryKey);
    useEtherSeo(craftEntry, opts, opts?.activeSite);

    // Extract potential parts
    const headerImage = computed(() => toValue(craftEntry)?.headerImage?.[0]);

    return {
        craftEntry,
        entryCount: toValue(response?.data)?.entryCount,
        pending: toRef(response, 'pending'),
        error: toRef(response, 'error'),
        headerImage,
    };
};

export default usePageQuery;
